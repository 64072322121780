<script lang="ts">
import { mapGetters } from 'vuex';
import years from '@/helpers/yearsOptions';

export default {
    props: {
        selectedClient: { type: Number , default: () => null}
    },
    components: {
        "MasterDetail": () => import("@/components/master-detail.vue"),
    },
    computed: {
        ...mapGetters(['clientId']), 
        customResource() {
            const id = this.clientId > 0 ? this.clientId : this.$props.selectedClient;            
            const resource = this.apiResource(`v1/clientes/${id}/csll`)
            return {
                ...resource,
                get(...args) {
                    return resource.get(...args).then((result) => {
                        return result;
                    }) 
                }
            }
        }
    },
    data() {
        return {
            opts: {
                years
            },
            cols: [
                {
                    key: 'id',
                    name: 'Código',
                    sort: 0,
                    hideInform: true,
                    hideInTable: true
                },
                {
                    key: 'aliquota',
                    name: 'Alíquota',
                    colSize: 6,
                    type: this.$fieldTypes.DOUBLE,
                    rules: [{ rule: "required" }],
                },                
                {
                    key: 'anoBase',
                    name: 'Ano base',
                    sort: 0,
                    colSize: 6,
                    type: this.$fieldTypes.SELECT,
                    rel: { to: "years", key: "value", name: "text" },  
                    rules: [{ rule: "required" }],                  
                },
                {
                    key: 'competenciaDe',
                    name: 'Competência De',
                    sort: 0,
                    colSize: 6,
                    type: this.$fieldTypes.MONTH,
                    rules: [{ rule: "required" }],
                },
                {
                    key: 'competenciaAte',
                    name: 'Competência Até',
                    sort: 0,
                    colSize: 6,
                    type: this.$fieldTypes.MONTH,
                    rules: [{ rule: "required" }],
                }, 
                {
                    key: "criado_em",
                    name: "Criado Em",
                    type: this.$fieldTypes.DATETIME,
                    hideInform: true,
                },
                {
                    key: "modificado_em",
                    name: "Modificado Em",
                    type: this.$fieldTypes.DATETIME,
                    hideInform: true,
                },
            ]
        }
    }
}
</script>

<template>
    <MasterDetail
        formTitle="Cadastro CSLL"
        :cols="cols"
        :opts="opts"
        has-year-filter
        :customResource="customResource">
    </MasterDetail>
</template>
